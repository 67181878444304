import { StandardError } from '@packages/common/src/errors/standard-error'

export class MissingImplementationError extends StandardError {}

export class InvalidPositionError extends StandardError {
  constructor(position: number, maxPosition?: number) {
    super(
      `invalid position, maximum position: ${maxPosition}, given: ${position}`
    )
  }
}

export class UnknownError extends StandardError {}
